







import {Component, Prop, Vue} from 'vue-property-decorator';
import {Day} from '@/timesheets/store/Timesheet';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';

@Component({
})
export default class HoursMismatch extends Vue {
    @Prop(Number)
    required!: number;

    @Prop(Number)
    reported!: number;

    @Prop(Object)
    day!: Day;

    @Prop(Boolean)
    dense!: boolean;

    get color(): string {
        if (this.reported > this.required) {
            return 'orange lighten-1';
        } else if (this.reported < this.required) {
            return 'error';
        } else {
            return '';
        }
    }

    get icon(): string {
        if (this.reported > this.required) {
            return 'trending_up';
        } else if (this.reported < this.required) {
            return 'trending_down';
        } else {
            return 'check';
        }
    }

    get flexDirection(): string {
        return this.dense ? 'flex-column' : 'flex-row';
    }

    get show(): boolean {
        // don't show time and icon when a leave request subtype allows an empty day
        if(this.day.leaveRequests?.length &&  this.day.leaveRequests?.some(lr => lr.leaveRequestStatus == LeaveRequestStatus.Approved && lr.leaveSubtypeAllowEmptyDay)) {
            return false;
        }else{
            return this.required > 0 || this.reported > 0;
        }
    }
}
