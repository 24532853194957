
































































import {VueConstructor} from 'vue';
import {Component, InjectReactive, Mixins, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ID} from '@/shared/store/AbstractEntity';
import {getProjects, saveTimesheet, submitTimesheet} from '@/timesheets/store/endpoints';
import {HermesUser, MeUser} from '@/employees/store/HermesUser';
import {monthAndYear} from '@/timesheets/shared/monthAndYear';
import {TimesheetGrid} from '@/timesheets/shared/TimesheetGrid';
import HideColumnBtn from '@/timesheets/editor/HideColumnBtn.vue';
import EditorButtons from '@/timesheets/editor/EditorButtons.vue';
import {Project} from '@/dictionaries/projects/store';
import TimesheetEditorVertical from '@/timesheets/editor/TimesheetEditorVertical.vue';
import TimesheetEditorHorizontal from '@/timesheets/editor/TimesheetEditorHorizontal.vue';
import {LocaleMessages} from 'vue-i18n';
import {getTotalReportedHours} from '@/timesheets/shared/getTotalReportedHours';
import {getTotalRequiredHours} from '@/timesheets/shared/getTotalRequiredHours';
import {getTotalReportedOvertime} from '@/timesheets/shared/getTotalReportedOvertime';
import {InjectShowMessage} from '@/shared/mixins/InjectShowMessage';
import {getReportedHoursForDay} from '@/timesheets/shared/getReportedHoursForDay';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import {getHoursOffForOvertime} from '@/timesheets/shared/getHoursOffForOvertime';

const employeesStore = namespace('employees');

@Component({
    components: {
        EditorButtons,
        HideColumnBtn,
    },
})
export default class TimesheetEditor extends Mixins(TimesheetGrid, InjectShowMessage) {
    @InjectReactive()
    readonly viewer!: MeUser;

    hideNonWorkingDays = localStorage.getItem('hideNonWorkingDays') !== 'false';
    hiddenActivities = [];
    horizontalEditor = localStorage.getItem('horizontalEditor') !== 'false';

    employeeProjects: Project[] = [];

    successMessage: string | LocaleMessages = '';

    canSubmitValidationMsg = '';

    currentWeeklyPage = 1;
    weeklyView = false;

    @employeesStore.Action('read')
    readEmployee!: (id: ID) => Promise<HermesUser>;

    @employeesStore.State('current')
    currentEmployee!: HermesUser;

    async mounted(): Promise<void> {
        await this.fetchData();

        if (this.timesheet?.employeeId !== this.viewer.id) {
            await this.$router.push({name: 'timesheets'});
        } else {
            await this.getProjects();
            await this.readEmployee(this.viewer.id);
        }
    }

    async getProjects(): Promise<void> {
        this.employeeProjects = await getProjects(this.viewer.id);
    }

    async onSave(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await this.apiCall(async () => {
            if(this.timesheet!=null){
                const responseTimesheet = await saveTimesheet(this.timesheet);
                this.timesheet.submitValidationResult = responseTimesheet?.submitValidationResult;
            }
        });

        if (!this.error) {
            this.showMessage(this.$t('timesheetSavedSuccessFully'), 'success');
        }
    }

    async onSaveAndReturn(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await this.apiCall(saveTimesheet, this.timesheet!);
        if (!this.error) {
            this.showMessage(this.$t('timesheetSavedSuccessFully'), 'success');
            await this.$router.push({name: 'timesheets'});
        }
    }

    async onSubmit(): Promise<void> {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        await this.apiCall(saveTimesheet, this.timesheet!);
        await this.apiCall(submitTimesheet, this.timesheet!);
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        if (!this.error) {
            this.showMessage(this.$t('timesheetSubmittedSuccessFully'), 'success');
            await this.$router.push({name: 'timesheets'});
        }
    }

    onImportError(value: string): void {
        if (value){
            this.showMessage(value, 'error');
        }
    }

    onAddActivity(id: ID): void {
        if (!this.timesheet) {
            return;
        }

        this.timesheet.days.forEach((day) => {
            day.activities.push({
                id,
                hours: 0,
                note: '',
                overtime: 0,
            });
        });
    }

    get sheetHeader(): string {
        if (!this.timesheet) {
            return '';
        }

        const date = monthAndYear(this.timesheet);
        return this.$t('timesheetFor', {date}) as string;
    }

    get canSubmit(): boolean {
        if (!this.timesheet) {
            return false;
        }
        this.canSubmitValidationMsg = '';

        const notWorkedOutAllRequiredHours = this.totalReportedHours + this.hoursOffForOvertime < this.totalRequiredHours;
        const requiredHoursExceededConvertToOvertime = this.totalReportedHours + this.hoursOffForOvertime > this.totalRequiredHours;
        const notAllAbsencesHaveAllowEmptyDay = this.hasEmptyDays;
        const notAllRequiredOvertimeReportedForAbsences = this.totalReportedOvertime < this.hoursOffForOvertime;

        if (this.currentEmployee?.employmentType === 'Employee') {
            // Employee rules

            if(notWorkedOutAllRequiredHours){
                this.canSubmitValidationMsg = 'NotWorkedOutAllRequiredHours';
            }else if(notAllAbsencesHaveAllowEmptyDay){
                this.canSubmitValidationMsg = 'NotAllAbsencesHaveAllowEmptyDay';
            }else if(notAllRequiredOvertimeReportedForAbsences){
                this.canSubmitValidationMsg = 'NotAllRequiredOvertimeReportedForAbsences';
            }else if(requiredHoursExceededConvertToOvertime){
                this.canSubmitValidationMsg = 'RequiredHoursExceededChangeToOvertime';
            }
            return !notWorkedOutAllRequiredHours
                && !notAllAbsencesHaveAllowEmptyDay
                && !notAllRequiredOvertimeReportedForAbsences
                && !requiredHoursExceededConvertToOvertime
                && this.timesheet.submitValidationResult.isValid;
        } else {
            // NonEmployee rules

            if(notAllAbsencesHaveAllowEmptyDay){
                this.canSubmitValidationMsg = 'NotAllAbsencesHaveAllowEmptyDay';
            }else if(notAllRequiredOvertimeReportedForAbsences) {
                this.canSubmitValidationMsg = 'NotAllRequiredOvertimeReportedForAbsences';
            }
            return !notAllAbsencesHaveAllowEmptyDay
                && !notAllRequiredOvertimeReportedForAbsences
                && this.timesheet.submitValidationResult.isValid;
        }
    }

    get hasEmptyDays(): boolean {
        if (!this.timesheet) {
            return false;
        }

        for (const day of this.timesheet.days) {
            if (day.requiredHours === 0) {
                continue;
            }
            if (getReportedHoursForDay(day) === 0) {
                if (day.leaveRequests?.some(lr => lr.leaveRequestStatus == LeaveRequestStatus.Approved && lr.leaveSubtypeAllowEmptyDay)) {
                    continue;
                }

                return true;
            }
        }
        return false;
    }

    get totalReportedHours(): number {
        if (this.timesheet) {
            return getTotalReportedHours(this.timesheet);
        } else {
            return 0;
        }
    }

    get totalRequiredHours(): number {
        if (this.timesheet) {
            return getTotalRequiredHours(this.timesheet);
        } else {
            return 0;
        }
    }

    get totalReportedOvertime(): number {
        if (this.timesheet) {
            return getTotalReportedOvertime(this.timesheet);
        } else {
            return 0;
        }
    }

    get hoursOffForOvertime(): number {
        if (this.timesheet) {
            return getHoursOffForOvertime(this.timesheet);
        } else {
            return 0;
        }
    }

    // Gets weeks in the month and indicates current week
    get weeks(): Array<Array<number>> {
        const weeks: Array<Array<number>> = [];

        if(!this.timesheet) {
            return weeks;
        }

        let week: Array<number> = [];
        let weeksIterator = 1; // weeks nums are used in pagination, so start from 1
        const todayDayNumber = new Date().getUTCDate();
        const todayMonthNumber = new Date().getUTCMonth() + 1; // January = 0, different to Hermes numeration

        for (let i = 0; i < this.timesheet.days.length; i++) {

            const day = this.timesheet.days[i];

            // if user visits current month, establish current week number
            if(todayMonthNumber == this.timesheet.month && day.day == todayDayNumber){
                this.currentWeeklyPage = weeksIterator;
            }

            week.push(day.day);

            const date = new Date(this.timesheet.year, this.timesheet.month - 1, day.day);
            const weekDayNumber = date.getDay();

            // when it is sunday or last day of the month, end the week
            if(weekDayNumber == 0 || i == this.timesheet.days.length - 1){
                weeks.push(week);
                week = [];
                weeksIterator++;
            }
        }

        return weeks;
    }

    // if user wants to see weeklyView return current week array, in the opposite case, return empty array
    get currentWeek(): number[] {
        if(this.weeklyView){
            return this.weeks[this.currentWeeklyPage-1];
        }else{
            return [];
        }
    }

    @Watch('hideNonWorkingDays')
    onHideNonWorkingDays(newValue: boolean): void {
        localStorage.setItem('hideNonWorkingDays', newValue.toString());
    }

    @Watch('horizontalEditor')
    onHorizontalEditor(newValue: boolean): void {
        localStorage.setItem('horizontalEditor', newValue.toString());
    }

    get editor(): VueConstructor<Vue> {
        if(this.weeklyView || this.horizontalEditor){
            return TimesheetEditorHorizontal;
        }else{
            return TimesheetEditorVertical;
        }
    }
}
