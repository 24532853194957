//source and explanation at: https://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
function parseCSV(str: string): string[][] {
    const arr: string [][] = [[]];
    let quote = false;  // true means we're inside a quoted field

    // iterate over each character, keep track of current row and column (of the returned array)
    for (let row = 0, col = 0, c = 0; c < str.length; c++) {
        const cc = str[c];
        const nc = str[c+1];        // current character, next character
        arr[row] = arr[row] || [];             // create a new row if necessary
        arr[row][col] = arr[row][col] || '';   // create a new column (start with empty string) if necessary

        // If the current character is a quotation mark, and we're inside a
        // quoted field, and the next character is also a quotation mark,
        // add a quotation mark to the current column and skip the next character
        if (cc == '"' && quote && nc == '"') {
            arr[row][col] += cc; ++c; continue;
        }

        // If it's just one quotation mark, begin/end quoted field
        if (cc == '"') {
            quote = !quote; continue;
        }

        // If it's a comma and we're not in a quoted field, move on to the next column
        if (cc == ',' && !quote) {
            ++col; continue;
        }

        // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
        // and move on to the next row and move to column 0 of that new row
        if (cc == '\r' && nc == '\n' && !quote) {
            ++row; col = 0; ++c; continue;
        }

        // If it's a newline (LF or CR) and we're not in a quoted field,
        // move on to the next row and move to column 0 of that new row
        if (cc == '\n' && !quote) {
            ++row; col = 0; continue;
        }
        if (cc == '\r' && !quote) {
            ++row; col = 0; continue;
        }

        // Otherwise, append the current character to the current column
        arr[row][col] += cc;
    }
    return arr;
}

function trasformDate(inputdate: string): string | null {
    if(isNaN(Date.parse(inputdate))) {
        return null;
    }

    let outputdate = new Date(inputdate);
    const offset = outputdate.getTimezoneOffset();
    outputdate = new Date(outputdate.getTime() - (offset * 60 * 1000));
    const formattedDate = outputdate.toISOString().split('T')[0];
    return formattedDate;
}

export function timesheetCSVParse(csvContent: string): Map<string, number> {
    const csvAsLines = parseCSV(csvContent);
    const csvHeaders = csvAsLines[0];

    const version1Headers = ['Hours', 'Time Spent (h)', 'Godziny'];
    const isVersion1 = csvHeaders.some(item => version1Headers.includes(item));

    if(isVersion1) {
        const csvBody = csvAsLines.slice(1);

        const hoursIndex = Math.max(
            csvHeaders.indexOf('Hours'),
            csvHeaders.indexOf('Time Spent (h)'),
            csvHeaders.indexOf('Godziny')
        );
        const workDateIndex = Math.max(
            csvHeaders.indexOf('Work date'),
            csvHeaders.indexOf('Date Started'),
            csvHeaders.indexOf('Data pracy')
        );

        return csvBody.reduce((previousValue: Map<string, number>, line: string[]) => {
            const workDateTime = line[workDateIndex];
            const hours = Number.parseFloat(line[hoursIndex].replace(',','.'));

            if (workDateTime === undefined) {
                return previousValue;
            }

            const workDate = line[workDateIndex].split(' ')[0];

            const aggregate: Map<string, number> = previousValue;
            const prevHours = previousValue.get(workDate) || 0;

            aggregate.set(workDate, prevHours + hours);

            return aggregate;
        }, new Map());
    }

    const csvHoursTotal = csvAsLines[csvAsLines.length - 1];
    return csvHoursTotal
        .reduce((previousValue: Map<string, number>, value: string, index: number) => {
            const aggregate: Map<string, number> = previousValue;
            const hours = parseFloat(value);
            if(value && !isNaN(hours)) {
                const date = csvHeaders[index];
                const formattedDate = trasformDate(date);
                if(formattedDate) {
                    aggregate.set(formattedDate, hours);
                }
            }

            return aggregate;
        }, new Map());
}
