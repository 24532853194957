





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {parseHourInput} from '@/timesheets/shared/parseHourInput';
import OvertimeIcon from '@/timesheets/editor/OvertimeIcon.vue';
@Component({
    components: {OvertimeIcon},
})
export default class OvertimeInput extends Vue {
    @Prop(Number)
    value!: number;

    @Prop(Boolean)
    disabled!: boolean;

    @Prop(Boolean)
    dense!: boolean;

    onInput(event: InputEvent): void {
        const value = parseHourInput((event.currentTarget as HTMLInputElement).value);

        this.$emit('input', value || 0);
    }

    onClick(event: MouseEvent): void {
        (event.currentTarget as HTMLInputElement).select();
    }
}
