






import {Component, Vue} from 'vue-property-decorator';
import {MetaInfo} from 'vue-meta';

import AppScreen from '@/layout/AppScreen.vue';

@Component({
    components: {
        AppScreen,
    },
    metaInfo(this: TimesheetsScreen): MetaInfo {
        return {
            titleTemplate: '%s — HERMES',
            title: this.$t('menuTimesheets') as string,
        };
    },
})
export default class TimesheetsScreen extends Vue {
    get isFullWidth(): boolean {
        return this.$route.name === 'fillTimesheet';
    }
}
