import {Timesheet} from '@/timesheets/store/Timesheet';
import {DateRange} from '@/shared/types/DateRange';

export function isTimesheetInRange(timesheet: Timesheet, range: DateRange): boolean {
    if (range.startDate > range.endDate) {
        return false;
    }

    const year = timesheet.year;
    const month = timesheet.month - 1; // JS Date's months are 0-based

    const startMonth = range.startDate.getMonth();
    const startYear = range.startDate.getFullYear();

    const afterStart = year === startYear ? month >= startMonth : year > startYear;

    if (afterStart) {
        const endMonth = range.endDate.getMonth();
        const endYear = range.endDate.getFullYear();

        return year === endYear ? month <= endMonth : year < endYear;
    }

    return false;
}
