var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-4 mt-4",attrs:{"elevation":"1","tile":""}},[_c('header',{staticClass:"headline mb-5"},[_vm._t("header"),_c('expand-button',{staticClass:"hidden-lg-and-up",model:{value:(_vm.filtersExpanded),callback:function ($$v) {_vm.filtersExpanded=$$v},expression:"filtersExpanded"}})],2),_c('filter-form',{attrs:{"expanded":_vm.filtersExpanded},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('v-data-table',_vm._b({staticClass:"elevation-0",attrs:{"headers":_vm.headers,"expanded":_vm.expanded,"loading":_vm.isFetching},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('timesheet-status-icon',{attrs:{"timesheet":item}})]}},(_vm.approved !== _vm.TimesheetStatus.Draft)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('list-action',{attrs:{"item":item,"approved":_vm.approved === _vm.TimesheetStatus.Approved},on:{"onApproveClick":function($event){return _vm.$emit('onApproveClick', item.id)},"onDeclineClick":function($event){return _vm.$emit('onDeclineClick', item.id)}}})]}}:null,{key:"item.month",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monthAndYear")(item))+" ")]}},{key:"item.hoursReported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hoursReported + item.overtimeReported)+" / "+_vm._s(item.hoursRequired)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('timesheet-viewer',{attrs:{"id":item.id,"hours-reported":item.hoursReported,"hours-required":item.hoursRequired,"overtime-reported":item.overtimeReported}})],1)]}}],null,true)},'v-data-table',_vm.tableProps(_vm.list),false)),_c('v-alert',{attrs:{"value":!!_vm.error,"color":"error","dense":"","dark":""}},[_vm._v(_vm._s(_vm.error))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }