





















import {VueConstructor} from 'vue';
import {Component, Mixins, Vue, Watch, Prop} from 'vue-property-decorator';
import {getTimesheet} from '@/timesheets/store/endpoints';
import {ID} from '@/shared/store/AbstractEntity';
import {TimesheetGrid} from '@/timesheets/shared/TimesheetGrid';
import TimesheetEditorHorizontal from '@/timesheets/editor/TimesheetEditorHorizontal.vue';
import TimesheetEditorVertical from '@/timesheets/editor/TimesheetEditorVertical.vue';

@Component({
    components: {
    },
})
export default class TimesheetViewer extends Mixins(TimesheetGrid) {
    @Prop(Number)
    hoursRequired!: number;

    @Prop(Number)
    hoursReported!: number;

    @Prop(Number)
    overtimeReported!: number;

    horizontalEditor = localStorage.getItem('horizontalEditor') !== 'false';

    @Watch('id')
    async onTimesheetChange(newValue: ID): Promise<void> {
        this.apiCall(async () => {
            this.timesheet = await getTimesheet(newValue);
        });
    }

    async mounted(): Promise<void> {
        await this.fetchData();
    }

    get viewer(): VueConstructor<Vue> {
        return this.horizontalEditor ? TimesheetEditorHorizontal : TimesheetEditorVertical;
    }
}
