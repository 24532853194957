















import {Component, Vue, Prop} from 'vue-property-decorator';
import {CalendarEvent} from '@/timesheets/store/Timesheet';

@Component
export default class CustomCalendarEvents extends Vue {
    @Prop(Array)
    events!: CalendarEvent[];
}
