var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"timesheetsList"},[_c('header',{staticClass:"headline mt-5 mb-5"},[_vm._v(_vm._s(_vm.$t('myTimesheets')))]),_c('v-data-table',_vm._b({staticClass:"elevation-0",attrs:{"server-items-length":_vm.totalCount,"loading":_vm.isFetching,"headers":_vm.headers,"options":_vm.options,"expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("monthAndYear")(item)))])]}},{key:"item.hoursReported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hoursReported + item.overtimeReported)+" / "+_vm._s(item.hoursRequired)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('timesheet-status-icon',{staticClass:"mr-4",attrs:{"timesheet":item}}),_c('v-btn',{staticClass:"mr-4",attrs:{"hidden":!_vm.showManageButtons(item),"to":{name: 'fillTimesheet', params: {id: item.id}},"small":"","data-automation-id":"edit"}},[_c('v-icon',[_vm._v("edit")]),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v(_vm._s(_vm.$t('editTimesheet')))])],1),_c('v-btn',{attrs:{"hidden":!_vm.showManageButtons(item),"disabled":!item.submitValidationResult.isValid,"color":"primary","small":"","data-automation-id":"submit"},on:{"click":function($event){_vm.timesheetToSubmit = item}}},[_c('v-icon',[_vm._v("send")]),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v(_vm._s(_vm.$t('submitTimesheet')))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('timesheet-viewer',{attrs:{"id":item.id,"hours-reported":item.hoursReported,"hours-required":item.hoursRequired,"overtime-reported":item.overtimeReported}})],1)]}}])},'v-data-table',_vm.tableProps(_vm.list),false)),_c('v-alert',{attrs:{"value":!!_vm.error,"color":"error","dense":"","dark":""}},[_vm._v(_vm._s(_vm.error))]),_c('confirm-submission',{attrs:{"show":_vm.timesheetToSubmit !== null},on:{"cancel":function($event){_vm.timesheetToSubmit = null},"confirm":function($event){return _vm.onSubmitClick(_vm.timesheetToSubmit)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }