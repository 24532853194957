import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import {WithNotes} from '@/timesheets/editor/WithNotes';
import {setLocalStorageSortOrder, updateCurrentSortOrder} from '@/timesheets/shared/TimesheetSortOrder';
import {getLocalStorageSortOrder} from '@/timesheets/shared/TimesheetSortOrder';
import {Day, Timesheet} from '@/timesheets/store/Timesheet';
import {ID} from '@/shared/store/AbstractEntity';
import {ActivityType, Project} from '@/dictionaries/projects/store';
import {sortBy} from '@/shared/sortBy';
import {Dict} from '@/shared/types/Dict';
import {roundValue} from '@/timesheets/shared/roundValue';
import {getDayName} from '@/timesheets/shared/getDayName';
import {padDateTime} from '@/shared/date/padDateTime';
import {setActivityValues} from '@/timesheets/shared/changeActivityValue';
import {activityNamesToMap} from '@/timesheets/shared/activityNamesToMap';
import {autofill} from '@/timesheets/shared/autofill';
import {getReportedHoursForDay} from '@/timesheets/shared/getReportedHoursForDay';
import {getHoursOffForOvertime} from '@/timesheets/shared/getHoursOffForOvertime';


@Component({
})

export class TimesheetEditor extends Mixins(WithNotes) {
    // implement prop.sync pattern
    @Prop(Object)
    timesheet!: Timesheet;

    @Watch('timesheet')
    onTimesheetChange(newValue: Timesheet): void {
        this.$emit('update:timesheet', newValue);
    }
    // /implement prop.sync pattern

    @Prop(Number)
    totalReportedHours!: number;

    @Prop(Number)
    totalRequiredHours!: number;

    @Prop(Number)
    totalReportedOvertime!: number;

    @Prop(Boolean)
    hideNonWorkingDays!: boolean;

    @Prop(Array)
    hiddenActivities!: ID[];

    @Prop(Array)
    allProjects!: Project[];

    @Prop(Array)
    employeeProjects!: Project[];

    @Prop(Boolean)
    readonly!: boolean;

    @Prop(Array)
    currentWeek!: Array<number>;

    setActivityValues = setActivityValues;

    orderedHeadersIds: Array<ID> = []
    timesheetSortOrderKey = 'TimesheetHeadersSortOrder'

    getHoursOffForOvertime = getHoursOffForOvertime(this.timesheet);
    /**
     * All activities from this timesheet
     */
    get activities(): ID[] {
        const flatActivities: ActivityType[] = this.$store.getters['dictionaries/flatActivities'];

        const uniqueIds = new Set<ID>();
        for (const day of this.timesheet.days) {
            for (const activity of day.activities) {
                uniqueIds.add(activity.id);
            }
        }

        const activities: Array<{id: ID; name: string}> = Array.from(uniqueIds).map((id) => {
            let name = '';
            const activityType = flatActivities.find(a => a.id === id);

            if (activityType) {
                name = activityType.name;
            }

            return {
                id,
                name,
            };
        });

        return sortBy(activities, 'name').map(a => a.id);
    }

    get activityNames(): Dict<string> {
        return activityNamesToMap(this.allProjects);
    }

    activityNameToHeader(activityId: ID): string {
        if (this.activityNames[activityId]) {
            return this.activityNames[activityId].replace(': ', ':<br/>');
        } else {
            return `unknown activity id: ${activityId}`;
        }
    }

    get _availableActivities(): ActivityType[] {
        const activities = new Set<ActivityType>();
        for (const project of this.employeeProjects) {
            for (const activity of project.activities) {
                activities.add(activity);
            }
        }

        return Array.from(activities);
    }

    get _availableActivitiesIds(): ID[] {
        return this._availableActivities.map(a => a.id);
    }

    get _availableBlockedActivityIds(): ID[] {
        return this._availableActivities.filter(a => a.isBlocked).map(a => a.id);
    }

    get blockedActivities(): ID[] {
        const blocked: ID[] = [];

        for (const activityId of this.activities) {
            // if employee can't add this activity column, then he shouldn't be able to edit it as well
            if (!this._availableActivitiesIds.includes(activityId)) {
                blocked.push(activityId);
            } else if (this._availableBlockedActivityIds.includes(activityId)) {
                blocked.push(activityId);
            }
        }

        return blocked;
    }

    weekDayNumber(day: number): number {
        const date = new Date(this.timesheet.year, this.timesheet.month - 1, day);
        return date.getDay();
    }

    dayClass(day: Day): object {
        const num = this.weekDayNumber(day.day);

        let isToday = false;
        let inFuture = false;
        const today = new Date();
        const {year, month} = this.timesheet;
        if (year === today.getUTCFullYear() && month === today.getUTCMonth() + 1) {
            const utcDate = today.getUTCDate();
            isToday = day.day === utcDate;
            inFuture = day.day > utcDate;
        }

        return {
            isHoliday: day.isHoliday,
            isToday,
            inFuture,
            [`day-${num}`]: true,
        };
    }

    get visibleDays(): Day[] {
        let result = this.timesheet.days;
        const weekly = this.currentWeek && this.currentWeek.length > 0;
        if (this.hideNonWorkingDays) {
            result = this.timesheet.days.filter((day) => {
                const weekDayNumber = this.weekDayNumber(day.day);
                return !day.isHoliday && weekDayNumber !== 6 && weekDayNumber !== 0;
            });
        }

        if (weekly){
            result = result.filter(x => x.day >= this.currentWeek[0] && x.day <= this.currentWeek[this.currentWeek.length-1]);
        }
        return result;
    }

    get reportedHours(): number[] {
        const result = [];

        for (const day of this.visibleDays) {
            result.push(getReportedHoursForDay(day));
        }

        return result;
    }

    get totalActivityHours(): Dict<number> {
        const result: Dict<number> = {};

        for (const day of this.timesheet.days) {
            if (day.activities.length) {
                for (const activity of day.activities) {
                    if (result[activity.id] === undefined) {
                        result[activity.id] = activity.hours;
                    } else {
                        result[activity.id] += activity.hours;
                    }
                }
            }
        }

        return result;
    }

    getActivityHours(day: Day, activityId: number): number {
        if (!day.activities.length) {
            return 0;
        }

        const found = day.activities.find((a) => a.id === activityId);
        if (found) {
            return roundValue(found.hours);
        } else {
            return 0;
        }
    }

    getActivityOvertime(day: Day, activityId: number): number {
        if (!day.activities.length) {
            return 0;
        }

        const found = day.activities.find((a) => a.id === activityId);
        if (found) {
            return roundValue(found.overtime);
        } else {
            return 0;
        }
    }

    hasBlockingLeaveSubtype(day: Day): boolean {
        if (!day.leaveRequests || day.leaveRequests.length == 0) {
            return false;
        }

        const blockingSubtypes: string[] = this.$store.getters['dictionaries/blockingLeaveSubtypes'];
        return day.leaveRequests?.some(lr => lr.leaveSubtypeName && blockingSubtypes.includes(lr.leaveSubtypeName));
    }

    onExternalTimesheet(activityId: ID, externalTimesheet: Map<string, number>): void {
        for (const [date, hours] of externalTimesheet) {
            const dateObj = new Date(date);
            const day = dateObj.getDate();
            const month = dateObj.getMonth() + 1;
            const year = dateObj.getFullYear();

            if (this.timesheet?.month !== month || this.timesheet?.year !== year) {
                this.$emit('onImportError', this.$t('timesheetImportInvalidPeriod'));
                return;
            }

            const dayObj: Day =  this.timesheet.days.find((d: Day) => d.day === day) as Day;
            setActivityValues(dayObj, activityId, hours, 0);
        }
    }

    autofill(activityId: ID): void {
        autofill(activityId, this.visibleDays, this.hasBlockingLeaveSubtype);
    }

    dayName(day: number): string {
        return getDayName(this.timesheet.year, this.timesheet.month, day);
    }

    dateColumn(day: Day): string {
        return `${padDateTime(day.day)}.${padDateTime(this.timesheet.month)}`;
    }

    mounted(): void{
        this.initializeOrderedHeadersIds();
    }

    /**
     * Loads to orderedHeadersIds variable activities (from the local storage or from the current state
     * if the local storage is not initialized)
     */
    initializeOrderedHeadersIds(): void{
        const currentSortOrder = getLocalStorageSortOrder(this.timesheetSortOrderKey, this.timesheet.id);

        if (currentSortOrder.length == 0){  // if the local storage has no TimesheetHeadersSortOrder values, save the current state
            this.saveOrderedHeadersIds(this.activities);

        }else{  // if there already are TimesheetHeadersSortOrder values in the local storage, load values from there
            updateCurrentSortOrder(this.activities, currentSortOrder);
            this.saveOrderedHeadersIds(currentSortOrder);
        }
    }

    /**
     * Saves reordered headers ids to the local storage
     */
    endOrderingHeadersIds(): void{
        this.saveOrderedHeadersIds(this.orderedHeadersIds);
    }

    /**
     * Handles addActivity signal - emits addActivity (once again) and if orderedHeadersIds
     * doesn't contain given ID adds it to that variable
     */
    @Emit('addActivity')
    onAddActivity(id: ID): void {
        if(!this.orderedHeadersIds.includes(id)){
            this.orderedHeadersIds.push(id);
            this.saveOrderedHeadersIds(this.orderedHeadersIds);
        }
    }

    /**
     * Saves given array to the local storage and to the TimesheetHeadersSortOrder variable
     */
    saveOrderedHeadersIds(headers: ID[]): void{
        setLocalStorageSortOrder(this.timesheetSortOrderKey, this.timesheet.id, headers);
        this.orderedHeadersIds = headers;
    }
}

