






















































































































import {Component, Mixins} from 'vue-property-decorator';

import ActivityHeader from '@/timesheets/editor/ActivityHeader.vue';
import CustomCalendarEvents from '@/timesheets/editor/CustomCalendarEvents.vue';
import LeaveBadge from '@/attendance/shared/LeaveBadge.vue';
import HoursMismatch from '@/timesheets/editor/HoursMismatch.vue';
import HoursInput from '@/timesheets/editor/HoursInput.vue';
import NoteIcon from '@/timesheets/editor/NoteIcon.vue';
import TotalHours from '@/timesheets/editor/TotalHours.vue';
import NotePopup from '@/timesheets/editor/NotePopup.vue';
import AvailableActivities from '@/timesheets/editor/AvailableActivities.vue';
import {TimesheetEditor} from '@/timesheets/shared/TimesheetEditor';
import draggable from 'vuedraggable';
import OvertimeInput from '@/timesheets/editor/OvertimeInput.vue';
import OvertimeIcon from '@/timesheets/editor/OvertimeIcon.vue';

@Component({
    components: {
        OvertimeIcon,
        OvertimeInput,
        AvailableActivities,
        NotePopup,
        TotalHours,
        NoteIcon,
        HoursInput,
        HoursMismatch,
        LeaveBadge,
        CustomCalendarEvents,
        ActivityHeader,
        draggable,
    },
})

export default class TimesheetEditorVertical extends Mixins(TimesheetEditor) {

}

