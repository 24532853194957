












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NoteIcon extends Vue {
    @Prop(String)
    note!: string;

    get icon(): string {
        if (this.note) {
            return 'insert_comment';
        } else {
            return 'add_comment';
        }
    }
}
