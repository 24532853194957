import {Timesheet} from '@/timesheets/store/Timesheet';

export function getHoursOffForOvertime(timesheet: Timesheet): number {
    let hoursOffForOvertime = 0;

    for (const day of timesheet.days) {
        day.leaveRequests?.forEach(lr => {
            if (lr.leaveSubtypeName === 'Day off for Over Time') {
                hoursOffForOvertime = hoursOffForOvertime + (day.requiredHours);
            }
        });
    }
    return hoursOffForOvertime;
}
