import {ID} from '@/shared/store/AbstractEntity';

export class TimesheetSortOrderStorage {
    orders: Array<TimesheetSortOrder>

    constructor(){
        this.orders = [];
    }
}

export class TimesheetSortOrder {
    id: number;
    sortOrder: Array<ID>

    constructor(id: number, sortOrder: Array<ID>){
        this.id = id;
        this.sortOrder = sortOrder;
    }
}

/**
 * Returns timesheet sort order from the local storage or an empty array
 */
export function getLocalStorageSortOrder(timesheetSortOrderKey: string, timesheetId: number): Array<ID>{
    const storageSortOrderString = localStorage.getItem(timesheetSortOrderKey);

    if(storageSortOrderString != null){  // Access TimesheetSortOrderStorage

        const storageSortOrder: TimesheetSortOrderStorage = JSON.parse(storageSortOrderString);
        const timesheetOrder = storageSortOrder.orders.filter(x=>x.id == timesheetId)[0];
        if(timesheetOrder != null) {
            return timesheetOrder.sortOrder;
        }
    }
    return [];
}

/**
 * Alters timesheet sort order in the local storage if that one actually exists. If timesheet sort order does not exist,
 * new item is added to the local storage. Initializes local storage timesheet orders structure if there is no one.
 */
export function setLocalStorageSortOrder(timesheetSortOrderKey: string, timesheetId: number, headers: Array<ID>): void {
    const storageSortOrderString = localStorage.getItem(timesheetSortOrderKey);

    if(storageSortOrderString != null){ // Access TimesheetSortOrderStorage

        const storageSortOrder: TimesheetSortOrderStorage = JSON.parse(storageSortOrderString);
        const currentSortOrder = storageSortOrder.orders.filter(x=>x.id == timesheetId)[0];

        if(currentSortOrder != null) { // Alter timesheetOrder if exists
            currentSortOrder.sortOrder = headers;
        }else{ // Add timesheetOrder if not exists
            storageSortOrder.orders.push(new TimesheetSortOrder(timesheetId, headers));
        }

        localStorage.setItem(timesheetSortOrderKey, JSON.stringify(storageSortOrder));

    }else{ // initialize TimesheetSortOrderStorage
        const newStorageSortOrder = new TimesheetSortOrderStorage();
        newStorageSortOrder.orders.push(new TimesheetSortOrder(timesheetId, headers));
        localStorage.setItem(timesheetSortOrderKey, JSON.stringify(newStorageSortOrder));
    }
}

/**
 * Makes sure sort order hasn't more/less items than current activities
 */
export function updateCurrentSortOrder(activities: number[], sortOrder: number[]): void{
    // Empty activity doesn't end up in the database, so after reloading we need to
    // get rid of them from the local storage
    const idxToSplice = [];
    for(let i = 0; i < sortOrder.length; i++){
        if(!activities.includes(sortOrder[i])){
            const idx = sortOrder.indexOf(sortOrder[i], 0);
            idxToSplice.push(idx);
        }
    }
    idxToSplice.sort();
    idxToSplice.reverse();
    for(let i = 0; i < idxToSplice.length; i++) {
        sortOrder.splice(idxToSplice[i], 1);
    }

    // If activity ends up in the database via other ways (like home dashboard)
    // we need to add it to the sort order (on the bottom of the list)
    for(let i = 0; i < activities.length; i++){
        if(!sortOrder.includes(activities[i])){
            sortOrder.push(activities[i]);
        }
    }
}
