import {Component, Vue} from 'vue-property-decorator';
import {Nullable} from '@/shared/types/Nullable';
import {Day} from '@/timesheets/store/Timesheet';

@Component({
})
export class WithNotes extends Vue {
    note = '';
    noteActivator: Nullable<Element> = null;
    currentDay: Nullable<Day> = null;
    currentActivityId: Nullable<number> = null;

    getNote(day: Day, activityId: number): string {
        const found = day.activities.find((a) => a.id === activityId);
        if (found) {
            return found.note || '';
        } else {
            return '';
        }
    }

    setNote(value: string): void {
        if (this.currentDay !== null && this.currentActivityId !== null) {
            const found = this.currentDay.activities.find((a) => a.id === this.currentActivityId);
            if (found) {
                found.note = value;
            } else {
                this.currentDay.activities.push({
                    note: this.note,
                    hours: 0,
                    id: this.currentActivityId,
                    overtime: 0,
                });
            }
        }
    }

    onNoteClick(event: MouseEvent, day: Day, activityId: number): void {
        this.currentActivityId = activityId;
        this.currentDay = day;
        this.noteActivator = event.currentTarget as Element;
        this.note = this.getNote(day, activityId);
    }

    onNoteClose(): void {
        this.setNote(this.note);
        Vue.nextTick(() => {
            this.currentActivityId = null;
            this.currentDay = null;
            this.noteActivator = null;
            this.note = '';
        });
    }

    /* v-dialog refuses to open second time if there's no unique key */
    getNotePopupKey(): string {
        if (this.currentDay && this.currentActivityId !== null) {
            return `${this.currentDay.day}-${this.currentActivityId}`;
        } else {
            return '';
        }
    }
}
