






















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {IsFullscreen} from '@/shared/mixins/IsFullscreen';
import AppDialog from '@/shared/AppDialog.vue';

@Component({
    components: {
        AppDialog,
    },
})
export default class ApproveAll extends Mixins(IsFullscreen) {
    @Prop(Number)
    readonly amount!: number;
}
