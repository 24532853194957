












import {Component, Prop, Vue} from 'vue-property-decorator';
import OvertimeIcon from '@/timesheets/editor/OvertimeIcon.vue';
@Component({
    components: {OvertimeIcon},
})
export default class TotalHours extends Vue {
    @Prop(Number)
    reported!: number;

    @Prop(Number)
    required!: number;

    @Prop(Number)
    overtime!: number;

    @Prop(Number)
    hoursOffForOvertime!: number;
}
