




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Timesheet, TimesheetStatus} from '../store/Timesheet';
import {ID} from '@/shared/store/AbstractEntity';

@Component
export default class ListAction extends Vue {
    @Prop(Object)
    item!: Timesheet;

    @Prop(Boolean)
    approved!: boolean;

    showDecline = false;
    declineReason = '';

    TimesheetStatus = TimesheetStatus;

    onDeclineClick(id: ID): void {
        if (this.showDecline) {
            this.$emit('onDeclineClick', id, this.declineReason);
            this.declineReason = '';
            this.showDecline = false;
        } else {
            this.showDecline = true;
        }
    }

    get showAccept(): boolean {
        return !this.showDecline;
    }

    get declineDisabled(): boolean {
        if (this.showDecline) {
            return this.declineReason.trim().length === 0;
        } else {
            return !this.approved;
        }
    }
}
