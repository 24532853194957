











































import {Component, Inject, Mixins, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';

import FilterForm from '@/shared/FilterForm.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {TableHeader} from '@/shared/types/TableHeader';
import {getReviewTimesheets} from '@/timesheets/store/endpoints';
import {Timesheet, TimesheetStatus} from '@/timesheets/store/Timesheet';
import {monthAndYear} from '@/timesheets/shared/monthAndYear';
import TimesheetViewer from '@/timesheets/shared/TimesheetViewer.vue';
import {like} from '@/shared/like';
import ListAction from '@/timesheets/manageable/ListAction.vue';
import TimesheetStatusIcon from '@/timesheets/shared/TimesheetStatusIcon.vue';
import {filtersFactory} from '@/shared/types/Filters';
import {isTimesheetInRange} from '@/timesheets/shared/isTimesheetInRange';
import {DataTableProps} from '@/shared/mixins/DataTableProps';
import ExpandButton from '@/shared/ExpandButton.vue';
import {FiltersExpanded} from '@/shared/mixins/FiltersExpanded';

@Component({
    components: {
        ExpandButton,
        TimesheetStatusIcon,
        ListAction,
        TimesheetViewer,
        FilterForm,
    },
    filters: {
        monthAndYear,
    },
})
export default class ManageableList extends Mixins(ApiCall, DataTableProps, FiltersExpanded) {
    @Prop(Number)
    approved!: TimesheetStatus;

    @Inject()
    readonly bus!: Vue;

    expanded = [];
    filters = filtersFactory();
    TimesheetStatus = TimesheetStatus;

    get headers(): TableHeader<Timesheet>[] {
        return [
            {
                text: this.$t('employeeName') as string,
                value: 'employeeName',
                width: '30%',
            },
            {
                text: this.$t('month') as string,
                value: 'month',
            },
            {
                text: this.$t('hoursReportedRequired') as string,
                value: 'hoursReported',
            },
            {
                text: '',
                value: 'status',
                width: 200,
                align: 'center',
            },
            {
                text: '',
                value: 'actions',
                width: 440,
                align: 'end',
            },
        ];
    }

    rawList: Timesheet[] = [];

    get list(): Timesheet[] {
        const likeTerm = like(this.filters.search);

        return this.rawList.filter((timesheet) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return likeTerm(timesheet.employeeName!) && isTimesheetInRange(timesheet, this.filters);
        });
    }

    @Watch('list')
    onListChange(newValue: Timesheet[]): void {
        this.$emit('update:count', newValue.length);
    }

    async mounted(): Promise<void> {
        await this.fetchData();

        this.bus.$on('updateList', this.fetchData);
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.rawList = await getReviewTimesheets(this.approved);
        });
    }
}
