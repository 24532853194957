







import {Component, Mixins, Prop} from 'vue-property-decorator';
import {DictionaryWidget} from '@/shared/mixins/DictionaryWidget';
import {LeaveType} from './store';
import {ID} from '@/shared/store/AbstractEntity';

@Component
export default class LeaveTypeName extends Mixins(DictionaryWidget) {
    @Prop(Number)
    value!: ID;

    get leaveType(): LeaveType {
        const leaveType = this.$store.state.dictionaries.leaveTypes.find((lt: LeaveType) => lt.id === this.value);

        return leaveType;
    }
}
