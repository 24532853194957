












import {Component, Prop, Vue} from 'vue-property-decorator';
import {Project} from '@/dictionaries/projects/store';
import ActivitiesList from '@/timesheets/shared/ActivitiesList.vue';

@Component({
    components: {
        ActivitiesList,
    },
})
export default class AvailableActivities extends Vue {
    @Prop(Array)
    projects!: Project[];

    @Prop(Boolean)
    leftTop!: boolean;

    get attrs(): object {
        return {
            style: {
                [this.leftTop ? 'left' : 'right']: 0,
            },

            xSmall: this.leftTop,
            left: this.leftTop,
            top: this.leftTop,

            small: !this.leftTop,
            right: !this.leftTop,
            bottom: !this.leftTop,
        };
    }
}
