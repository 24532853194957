


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {timesheetCSVParse} from '@/timesheets/shared/timesheetCSVParse';
import HideColumnBtn from '@/timesheets/editor/HideColumnBtn.vue';

@Component({
    components: {
        HideColumnBtn,
    },
})
export default class ActivityHeader extends Vue {
    @Prop(String)
    activityName!: string;

    @Prop(Boolean)
    twoLine!: boolean;

    @Prop(Boolean)
    readonly!: boolean;

    dragOver = false;

    onHeaderDragOver(event: DragEvent): void {
        this.dragOver = true;
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
        }
        event.preventDefault();
    }

    onHeaderDragLeave(): void {
        this.dragOver = false;
    }

    onHeaderDrop(event: DragEvent): void {
        this.dragOver = false;

        event.preventDefault();

        const files = event.dataTransfer?.files;
        if (!files || files.length !== 1) {
            return;
        }

        const csvFile = files[0];
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent): void => {
            const csvContent = (e.target as FileReader).result as string;

            const externalTimesheet = timesheetCSVParse(csvContent);

            this.$emit('externalTimesheet', externalTimesheet);

        };

        reader.readAsText(csvFile);
    }
}
