









































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Timesheet} from '@/timesheets/store/Timesheet';
import ConfirmSubmission from '@/timesheets/ConfirmSubmission.vue';
import {isTimesheetEditable} from '@/timesheets/shared/isTimesheetEditable';

@Component({
    components: {
        ConfirmSubmission,
    },
})
export default class EditorButtons extends Vue {
    @Prop(Object)
    timesheet!: Timesheet;

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    canSubmit!: boolean;

    @Prop(String)
    canSubmitValidationMsg!: string;

    showConfirmSubmission = false;

    get doesStatusAllowSaving(): boolean {
        return isTimesheetEditable(this.timesheet);
    }

    get isSubmitDisabled(): boolean {
        return !(this.canSubmit && this.doesStatusAllowSaving);
    }
}
