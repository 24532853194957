
























































import {Component, InjectReactive, Mixins, Watch} from 'vue-property-decorator';

import {TableHeader} from '@/shared/types/TableHeader';
import {MeUser} from '@/employees/store/HermesUser';
import {monthAndYear} from '@/timesheets/shared/monthAndYear';
import TimesheetViewer from '@/timesheets/shared/TimesheetViewer.vue';
import {Timesheet, TimesheetStatus} from '@/timesheets/store/Timesheet';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {fetchTimesheetsPage, submitTimesheet} from '@/timesheets/store/endpoints';
import TimesheetStatusIcon from '@/timesheets/shared/TimesheetStatusIcon.vue';
import ConfirmSubmission from '@/timesheets/ConfirmSubmission.vue';
import {Nullable} from '@/shared/types/Nullable';
import {DataTableProps} from '@/shared/mixins/DataTableProps';

@Component({
    components: {
        ConfirmSubmission,
        TimesheetStatusIcon,
        TimesheetViewer,
    },
    filters: {
        monthAndYear,
    },
})
export default class TimesheetsList extends Mixins(ApiCall, DataTableProps) {
    @InjectReactive()
    readonly viewer!: MeUser;

    list: Timesheet[] = [];

    timesheetToSubmit: Nullable<Timesheet> = null;

    expanded = [];

    options: { itemsPerPage: number; page: number } = {itemsPerPage: 10, page: 1};

    totalCount = 0;

    @Watch('options')
    async watchFirstMesh(newValue: any): Promise<void> {
        await this.fetchList();
    }

    get headers(): TableHeader<Timesheet>[] {
        return [
            {
                text: this.$t('month') as string,
                value: 'date',
            },
            {
                text: this.$t('hoursReportedRequired') as string,
                value: 'hoursReported',
            },
            {
                text: '',
                value: 'actions',
            },
        ];
    }

    async mounted(): Promise<void> {
        await this.fetchList();
    }

    async fetchList(): Promise<void> {
        await this.apiCall(async () => {
            const result = await fetchTimesheetsPage(
                this.viewer.id,
                this.options.page,
                this.options.itemsPerPage);

            this.totalCount = result.totalCount;
            this.list = result.timesheets;
        });
    }

    async onSubmitClick(timesheet: Timesheet): Promise<void> {
        this.timesheetToSubmit = null;
        await this.apiCall(submitTimesheet, timesheet);

        await this.fetchList();
    }

    showManageButtons(timesheet: Timesheet): boolean {
        return timesheet.status === TimesheetStatus.Draft || timesheet.status === TimesheetStatus.Declined;
    }
}
