














import {Component, Prop, Vue} from 'vue-property-decorator';
import {Nullable} from '@/shared/types/Nullable';

@Component
export default class NotePopup extends Vue {
    @Prop(Element)
    activator!: Nullable<Element>;

    @Prop(String)
    value!: string;

    onClose(): void {
        this.$emit('close');
    }

    get maxWidth(): string | number {
        if (this.$vuetify.breakpoint.mdAndDown) {
            return this.$vuetify.breakpoint.width;
        } else {
            return '50%';
        }
    }
}
