import {Day} from '@/timesheets/store/Timesheet';
import {ID} from '@/shared/store/AbstractEntity';
import {getReportedHoursForDay} from '@/timesheets/shared/getReportedHoursForDay';

export function autofill(activityId: ID, days: Day[], hasBlockingLeaveSubtype: (day: Day) => boolean): void {
    for (const day of days) {
        if (hasBlockingLeaveSubtype(day)) {
            continue;
        }

        const requiredHours = day.requiredHours;
        const reportedHoursForDay = getReportedHoursForDay(day);

        if (requiredHours > 0 && requiredHours > reportedHoursForDay) {
            const activity = day.activities.find((activity) => activity.id === activityId);
            const otherActivitiesSum = day.activities.reduce((acc, activity) => {
                if (activity.id !== activityId) {
                    acc += activity.hours;
                }
                return acc;
            }, 0);

            const hours = otherActivitiesSum === 0 ? requiredHours : requiredHours - reportedHoursForDay;

            if (activity) {
                activity.hours = Math.min(activity.hours + hours, requiredHours);
            } else {
                day.activities.push({
                    id: activityId,
                    hours,
                    note: '',
                    overtime: 0,
                });
            }
        }
    }
}
