import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {Nullable} from '@/shared/types/Nullable';
import {ID} from '@/shared/store/AbstractEntity';
import {Timesheet} from '@/timesheets/store/Timesheet';
import {getTimesheet} from '@/timesheets/store/endpoints';
import {Project} from '@/dictionaries/projects/store';

@Component({

})
export class TimesheetGrid extends Mixins(ApiCall) {
    @Prop()
    readonly id!: ID;

    timesheet: Nullable<Timesheet> = null;
    allProjects: Project[] = [];

    async getAllProjects(): Promise<void> {
        if (this.$store.state.dictionaries.projects.length === 0) {
            await this.$store.dispatch('dictionaries/fetch');
        }

        this.allProjects = this.$store.state.dictionaries.projects;
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.timesheet = await getTimesheet(this.id);
            await this.getAllProjects();
        });
    }
}
