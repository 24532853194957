


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import LeaveStatusIcon from '@/attendance/shared/LeaveStatusIcon.vue';
import {formatRange} from '@/attendance/shared/formatRange';
import {LeaveTypeEnum, LeaveTypeIconEnum} from '@/attendance/store/LeaveTypeEnum';
import LeaveTypeName from '@/dictionaries/leaveTypes/LeaveTypeName.vue';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';

@Component({
    components: {
        LeaveTypeName,
        LeaveStatusIcon,
    },
    filters: {
        formatRange,
    },
})
export default class LeaveBadge extends Vue {
    @Prop(Object)
    leave!: LeaveRequest;

    @Prop(Boolean)
    small!: boolean;

    LeaveRequestStatus = LeaveRequestStatus;

    get icon(): string {
        return LeaveTypeIconEnum[this.leave.leaveTypeId as LeaveTypeEnum] as string || 'hourglass_empty';
    }

    get iconSize(): object {
        return {
            small: this.small,
        };
    }
}
