
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {
    LeaveRequestStatus,
    LeaveRequestStatusColor,
    LeaveRequestStatusIcon,
} from '@/attendance/store/LeaveRequestStatus';
import {statusI18n} from '@/attendance/shared/statusI18n';

@Component({
    filters: {
        statusI18n,
    },
})
export default class LeaveStatusIcon extends Vue {
    @Prop(Number)
    status!: LeaveRequestStatus;

    LeaveRequestStatusIcon = LeaveRequestStatusIcon;
    LeaveRequestStatusColor = LeaveRequestStatusColor;
}
