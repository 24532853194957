







































import {Component, Mixins, Provide} from 'vue-property-decorator';
import Vue from 'vue';
import {TimesheetStatus} from '@/timesheets/store/Timesheet';
import ManageableList from './ManageableList.vue';
import {ID} from '@/shared/store/AbstractEntity';
import ApproveAll from '@/shared/ApproveAll.vue';
import {approveAllTimesheets, approveTimesheet, declineTimesheet} from '@/timesheets/store/endpoints';
import {ApiCall} from '@/shared/mixins/ApiCall';

@Component({
    components: {
        ApproveAll,
        ManageableList,
    },
})
export default class ManageableScreen extends Mixins(ApiCall) {
    @Provide()
    private bus = new Vue();
    TimesheetStatus = TimesheetStatus;
    massApproveCount = 0;
    confirmCount = -1;

    async onApproveClick(id: ID): Promise<void> {
        this.error = '';
        await this.apiCall(approveTimesheet, id);
        this.bus.$emit('updateList');
    }

    async onDeclineClick(id: ID, declineReason: string): Promise<void> {
        this.error = '';
        await this.apiCall(declineTimesheet, id, declineReason);

        this.bus.$emit('updateList');
    }

    async onConfirmApproveAll(): Promise<void> {
        this.confirmCount = -1;
        this.error = '';
        await this.apiCall(approveAllTimesheets);
        this.bus.$emit('updateList');
    }

    onCancelApprove(): void {
        this.confirmCount = -1;
        this.error = '';
    }
}
