import {Timesheet} from '@/timesheets/store/Timesheet';

export function getTotalReportedOvertime(timesheet: Timesheet): number {
    let result = 0;

    for (const day of timesheet.days) {
        if (day.activities.length) {
            result += day.activities.reduce<number>((acc, activity) => {
                return acc + activity.overtime;
            }, 0);
        }
    }

    return result;
}
